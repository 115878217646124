import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
})
export default new Vuex.Store({
    state: {
        showBam: false,
        isVoter: true,
        isDarkMode: false,
        isConnected: false,
        showVotes: false,
        voters: [],
        darkMode: false,
        newVote: false,
    },
    mutations: {
        setBam(state, val) {
            state.showBam = val;
        },
        setIsVoter(state, val) {
            state.isVoter = val;
        },
        setIsDarkMode(state, val) {
            state.isDarkMode = val;
        },
        setIsConnected(state, val) {
            state.isConnected = val;
        },
        setVoters(state, val) {
            state.voters = val.Items;
            state.showVotes = false;
            state.showBam = false;
        },
        setShowVotes(state, val) {
            state.showVotes = val;
        },
        switchTheme(state) {
            state.darkMode = !state.darkMode;
        },
        newVote(state) {
            state.newVote = !state.newVote;
        }
    },
    getters: {
        isVoter: state => {
            return state.isVoter;
        }
    },
    actions: {
        vote: (context, vote) => {
            Vue.prototype.$socket.sendObj({
                action: 'vote',
                message: vote
            });
        },
        resetVote: () => {
            Vue.prototype.$socket.sendObj({
                action: 'reset'
            });
        },
        toggleVoter: (context, val) => {
            Vue.prototype.$socket.sendObj({
                action: 'toggleVoter',
                value: val
            });
        },
        newVote: (context) => {
            context.commit('newVote');
        },
        kickAll: () => {
            console.log('kick all');
            Vue.prototype.$socket.sendObj({
                action: 'kickAll',
            });
        }
    },
    modules: {},
    plugins: [vuexLocal.plugin]
})