import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import VueCookies from 'vue-cookies';
import VueNativeSock from 'vue-native-websocket';

Vue.config.productionTip = false

Vue.use(VueNativeSock, process.env.VUE_APP_WEBSOCKETURI + "/ws?isVoter=" + store.getters.isVoter, {
    store: store,
    format: "json",
    reconnection: true, // (Boolean) whether to reconnect automatically (false)
    reconnectionAttempts: 10, // (Number) number of reconnection attempts before giving up (Infinity),
    reconnectionDelay: 3000,
    passToStoreHandler: function (eventName, event) {
        let data;
        if (typeof event.data == "undefined" || event.data === "") {
            return;
        }
        console.log(event.data);
        data = JSON.parse(event.data);
        if (eventName === "SOCKET_onmessage" && data.action) {
            switch (data.action) {
                case 'voters':
                    this.store.commit("setVoters", data.message);
                    break;
                case 'reset':
                    this.store.dispatch("newVote");
                    break;
                case 'disconnect':
                    this.store.commit("setIsConnected", false);
                    break;
                default:
                    break;
            }
        }
    }
});

Vue.use(VueCookies);

new Vue({
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')