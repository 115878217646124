<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h2>Estimate:</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3" md="1" sm="2" class="d-flex justify-center" v-for="vote in votersList" :key="vote.id">
                <v-btn>
                    <span v-if="vote.vote !== '' && showVotes === false">X</span>
                    <span v-else-if="vote.vote === '' && showVotes === false"></span>
                    <span v-else>{{vote.vote}}</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapState } from 'vuex';
    export default {
        computed: {
            ...mapState(['voters', 'showVotes']),
            votersList: {
                get() {
                    return this.voters;
                },
                set(val) {
                    this.voters = val;
                },
            },
        },
        methods: {
            checkIfAllVoted() {},
            checkVotesAreSame() {
                let i = 0;
                let b = '';
                if (typeof this.votersList === 'undefined' || this.voters.length === 0) {
                    return;
                }
                if (this.votersList.length === 1) {
                    this.$store.commit('setShowVotes', true);
                    return;
                }
                for (let voter of this.votersList) {
                    if (voter.vote === '') {
                        return;
                    }
                    if (i === 0) {
                        b = voter.vote;
                    } else {
                        if (b !== voter.vote) {
                            return;
                        }
                    }
                    i++;
                }
                this.$store.commit('setShowVotes', true);
                this.$store.commit('setBam', true);
            },
            checkVotes() {
                let allVoted = true;
                let allTheSame = true;
                let i = 0;
                let b = '';
                for (let voter of this.votersList) {
                    if (voter.vote === '') {
                        allVoted = false;
                    }
                    if (i === 0) {
                        b = voter.vote;
                    }
                    if (b !== voter.vote || voter.vote === '') {
                        allTheSame = false;
                    }
                    i++;
                }
                if (allVoted === true) {
                    this.$store.commit('setShowVotes', true);
                }
                if (allTheSame === true) {
                    this.$store.commit('setBam', true);
                }
            },
        },
        watch: {
            voters: {
                handler: 'checkVotes',
            },
        },
    };
</script>