<template>
    <v-row justify="center">

        <v-dialog hide-overlay class="d-flex flex-column justify-space-between" transition="dialog-bottom-transition" v-model="show">
            <v-img class="centerImg d-flex" src="@/assets/bam.png" max-height="150" max-width="250"></v-img>
        </v-dialog>
    </v-row>
</template>
<script>
    import { mapState } from 'vuex';
    export default {
        computed: {
            ...mapState(['showBam']),
            show: {
                get() {
                    return this.showBam;
                },
                set(val) {
                    this.$store.commit('setBam', val);
                },
            },
        },
    };
</script>
<style scoped>
    .centerImg {
        margin: 0px auto;
    }
</style>