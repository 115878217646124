<template>
    <v-container>
        <v-row>
            <v-col cols="4">
                <v-checkbox v-model="getIsVoter" label="Are you a voter?" @change="toggleVoter()" :key="isVoter" color="primary"></v-checkbox>
            </v-col>
            <v-col cols="8" class="d-flex justify-end">
                <v-btn class="kick-button" @click="kickAll">GTFO</v-btn>
                <v-btn @click="reset">Reset</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        data() {
            return {
                cardSet: 'fib',
                showVotes: false,
            };
        },
        computed: {
            ...mapState(['isVoter', 'resetVote']),
            getIsVoter: {
                get() {
                    return this.isVoter;
                },
                set(val) {
                    this.$store.commit('setIsVoter', val);
                },
            },
        },
        methods: {
            reset() {
                this.$store.dispatch('resetVote');
                this.$store.commit('setBam', false);
            },
            toggleVoter() {
                this.$store.dispatch('toggleVoter', this.isVoter);
            },
            kickAll() {
                this.$store.dispatch('kickAll');
            },
        },
    };
</script>

<style scss>
    .kick-button {
        margin-right: 1rem;
    }
</style>
