<template>
    <v-container>
        <v-row>
            <v-col cols="3" md="1" sm="2" class="d-flex justify-center" v-for="card in selectedCards" :key="card.value">
                <v-btn @click="sendVote(card)" :class="{'cur-vote': card.isActive}">{{card.val}}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        data() {
            return {
                cardLayouts: [
                    {
                        name: 'fib',
                        cards: [
                            { val: '0', isActive: false },
                            { val: '1', isActive: false },
                            { val: '2', isActive: false },
                            { val: '3', isActive: false },
                            { val: '5', isActive: false },
                            { val: '8', isActive: false },
                            { val: '13', isActive: false },
                            { val: '21', isActive: false },
                            { val: '34', isActive: false },
                            { val: '55', isActive: false },
                            { val: '89', isActive: false },
                            { val: '?', isActive: false },
                        ],
                    },
                ],
                selection: [],
            };
        },
        computed: {
            ...mapState(['newVote']),
            selectedCards: {
                set(val) {
                    this.selection = val;
                },
                get() {
                    return this.selection;
                },
            },
        },
        methods: {
            sendVote(vote) {
                this.unsetActive();
                vote.isActive = true;
                this.$store.dispatch('vote', vote.val);
            },
            unsetActive() {
                for (let card of this.selectedCards) {
                    card.isActive = false;
                }
            },
        },
        mounted() {
            this.selectedCards = this.cardLayouts[0].cards;
        },
        watch: {
            newVote(oldV, newV) {
                if (oldV !== newV) {
                    this.unsetActive();
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
    .cur-vote {
        border: 1px solid;
        border-color: var(--v-primary-base);
    }
</style>