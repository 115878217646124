<template>
    <v-app-bar app dark :color="getBarColor(darkMode)">

        <v-card class="pl-4 pr-4" :color="getConnected">Connected</v-card>
        <v-spacer></v-spacer>
        <v-switch class="mt-4" v-model="getTheme" inset label="Dark mode" persistent-hint></v-switch>
    </v-app-bar>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        computed: {
            ...mapState(['isConnected', 'darkMode']),
            getTheme: {
                get() {
                    return this.darkMode;
                },
                set() {
                    this.$store.commit('switchTheme');
                    this.$vuetify.theme.dark = this.darkMode;
                },
            },
            getConnected() {
                if (this.isConnected === false) {
                    return 'error';
                } else {
                    return 'success';
                }
            },
        },
        methods: {
            getBarColor() {
                if (this.darkMode) {
                    return 'darken-3';
                }
                return 'primary';
            },
        },
        mounted() {
            this.$vuetify.theme.dark = this.darkMode;
        },
    };
</script>