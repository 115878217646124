<template>
    <v-app>

        <TopBar />
        <v-main>
            <Control />
            <Cards />
            <Votes />
            <Bam />
        </v-main>
    </v-app>
</template>

<script>
    import Control from './components/Control';
    import Cards from './components/Cards';
    import Votes from './components/Votes';
    import Bam from './components/Bam';
    import TopBar from './components/TopBar';

    export default {
        name: 'App',
        data() {
            return {
                connected: false,
                connectionErrorCounter: 0,
            };
        },
        sockets: {
            connect: function () {
                this.$store.commit('setIsConnected', true);
            },
            open: function () {
                this.$store.commit('setIsConnected', true);
            },
            reconnect: function () {
                this.$store.commit('setIsConnected', true);
                this.$socket.sendObj({ action: 'getVoters' });
            },
            onopen: function () {
                this.$store.commit('setIsConnected', true);
                this.$socket.sendObj({ action: 'getVoters' });
            },
            disconnect: function () {
                this.$store.commit('setIsConnected', false);
            },
            close: function() {
                this.$store.commit('setIsConnected', false);
            },
            onclose: function() {
                this.$store.commit('setIsConnected', false);
            },
            error: function () {
                this.$store.commit('setIsConnected', false);
            },
            onerror: function () {
                this.$store.commit('setIsConnected', false);
            },
        },
        methods: {},
        components: {
            Control,
            Cards,
            Votes,
            Bam,
            TopBar,
        },
        mounted() {},
    };
</script>
